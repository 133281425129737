<template>
  <router-view></router-view>
</template>

<script>
//import axios from './axios-auth'
export default {
  name: 'App',
  created(){
   
 
  }
}
</script>

<style lang="scss">
  // Import Main styles for this application
  @import 'assets/scss/style';
</style>
