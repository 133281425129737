import Vue from 'vue'
import Router from 'vue-router'
import store from "../store/index";
import axios from "../axios-auth";
import { async } from 'regenerator-runtime';
//import { component } from 'vue/types/umd';
// Containers
const TheContainer = () => import('@/containers/TheContainer')

// Views
//const Dashboard = () => import('@/views/Dashboard')
//const test = () => import('@/views/test')

//const Colors = () => import('@/views/theme/Colors')
const Dashboard = () => import('@/views/pages/deigmatolhpsies/Dashboard')
const Login = () => import('@/views/pages/Login_new')
const Home = () => import('@/views/pages/Home')
const Form1 = () => import('@/views/pages/deigmatolhpsies/forms/form1')
const Form2 = () => import('@/views/pages/deigmatolhpsies/forms/form2')
const profile = () => import('@/views/pages/profile')
const management = () => import('@/views/pages/management')
const fotosimia = () => import('@/views/pages/fotosimia/fotosimiaMain')
const map = () => import('@/views/pages/xartis_vasis/xartisVasisMain')
const reports = () => import('@/views/pages/reports/ReportsMain')
const qna = () => import('@/views/pages/qna')
const ViewOlnly = () => import('@/views/pages/view_only')
//const Typography = () => import('@/views/theme/Typography')
/*

//const Charts = () => import('@/views/charts/Charts')
const Widgets = () => import('@/views/widgets/Widgets')

// Views - Components
const Cards = () => import('@/views/base/Cards')
const Forms = () => import('@/views/base/Forms')
const Switches = () => import('@/views/base/Switches')
const Tables = () => import('@/views/base/Tables')
const Tabs = () => import('@/views/base/Tabs')
const Breadcrumbs = () => import('@/views/base/Breadcrumbs')
const Carousels = () => import('@/views/base/Carousels')
const Collapses = () => import('@/views/base/Collapses')
const Jumbotrons = () => import('@/views/base/Jumbotrons')
const ListGroups = () => import('@/views/base/ListGroups')
const Navs = () => import('@/views/base/Navs')
const Navbars = () => import('@/views/base/Navbars')
const Paginations = () => import('@/views/base/Paginations')
const Popovers = () => import('@/views/base/Popovers')
const ProgressBars = () => import('@/views/base/ProgressBars')
const Tooltips = () => import('@/views/base/Tooltips')

// Views - Buttons
const StandardButtons = () => import('@/views/buttons/StandardButtons')
const ButtonGroups = () => import('@/views/buttons/ButtonGroups')
const Dropdowns = () => import('@/views/buttons/Dropdowns')
const BrandButtons = () => import('@/views/buttons/BrandButtons')

// Views - Icons
const CoreUIIcons = () => import('@/views/icons/CoreUIIcons')
const Brands = () => import('@/views/icons/Brands')
const Flags = () => import('@/views/icons/Flags')

// Views - Notifications
const Alerts = () => import('@/views/notifications/Alerts')
const Badges = () => import('@/views/notifications/Badges')
const Modals = () => import('@/views/notifications/Modals')

// Views - Pages
const Page404 = () => import('@/views/pages/Page404')
const Page500 = () => import('@/views/pages/Page500')
const Login = () => import('@/views/pages/login_new')
//const Login = () => import('@/views/pages/Login')
const Register = () => import('@/views/pages/Register')

// Users
const Users = () => import('@/views/users/Users')
const User = () => import('@/views/users/User')
*/
Vue.use(Router)

export default new Router({
  mode: 'history',
  linkActiveClass: 'active',
  base: process.env.BASE_URL,
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes()
})

function configRoutes () {
  return [
    {
      path: '/',
      redirect: '/login',
    },
    
    {
      
      path: '/dashboard',
      redirect: '/home',
      name: 'Dashboard',
      component: TheContainer,
      
      children: [
        /*{
          path: 'dashboard',
          name: 'Dashboard',
          component: Dashboard,
          beforeEnter(to, from, next) {
            testAuth(next);
          },
        },*/
        {
          path: "/home",
          name: "Home",
          component: Home,
          beforeEnter(to, from, next) {
            testAuth(next,'Home');
          }
        },
        
        {
          path: '/forms',
          //redirect: '/forms/form1',
          name: 'Δειγματοληψίες',
          component:Dashboard,
          beforeEnter(to, from, next) {
            testAuth(next,'Δειγματοληψίες');
          },
          children: [
            {
              path: "form1",
              name: "forms",
              component: Form1,
              beforeEnter(to, from, next) {
                testAuth(next,'form1');
              }
            },
            {
              path: "form2",
              name: "form2",
              component: Form2,
              beforeEnter(to, from, next) {
                testAuth(next);
              }
            },
            {
              path: "form3",
              name: "form3",
              component: Form1,
              beforeEnter(to, from, next) {
                testAuth(next);
              }
            },
            {
              path: "form4",
              name: "form4",
              component: Form1,
              beforeEnter(to, from, next) {
                testAuth(next);
              }
            }
          ]
         
        },
        {
          path: "/fotosimia",
          name: "Φωτοσημεία",
          component: fotosimia,
          beforeEnter(to, from, next) {
            testAuth(next,'Φωτοσημεία');
          }
        },
        {
          path: "/map",
          name: "Χάστης Στρωμάτωσης",
          component: map,
          beforeEnter(to, from, next) {
            testAuth(next,'Χάστης Στρωμάτωσης');
          }
        },
        {
          path: "/reports",
          name: "Reports",
          component: reports,
          beforeEnter(to, from, next) {
            testAuth(next,'Reports');
          }
        },
        {
          path: "/q&a",
          name: "q&a",
          component: qna,
          beforeEnter(to, from, next) {
            testAuth(next,'q&a');
          }
        },
        {
          path: "/view_only",
          name: "view_only",
          component: ViewOlnly,
          beforeEnter(to, from, next) {
            testAuth(next,'view_only');
          }
        },
        
        
        
        {
          path: "/profile",
          name: "Profile",
          component: profile,
          beforeEnter(to, from, next) {
            testAuth(next,'Profile');
          }
        },
        {
          path: "/management",
          name: "Management",
          component: management,
          beforeEnter(to, from, next) {
            testAuth(next,'Management');
          }
        },
        
      ]
    },
    {
      
      path: '/login',
      //redirect: '/dashboard',
      name: 'login',
      component: Login,
      
    },
   /* 
    {
      path: '/pages',
      redirect: '/pages/404',
      name: 'Pages',
      component: {
        render (c) { return c('router-view') }
      },
      children: [
        {
          path: '404',
          name: 'Page404',
          component: Page404
        },
        {
          path: '500',
          name: 'Page500',
          component: Page500
        },
       {
          path: 'login',
          name: 'Login',
          component: Login
        },
        {
          path: 'register',
          name: 'Register',
          component: Register
        }
      ]
    },*/
    
  ]
}

var group_view={
  'Home':[1,2,3,4,5,6,7,8,998,999,'view_only','view_edit'],
  'Management':[999,'view_edit'],
  'Profile':[1,2,3,4,5,6,7,8,998,999,'view_only','view_edit'],
  'view_only':[1,2,3,4,5,6,7,8,998,999,'view_only','view_edit'],
  'q&a':[999,'view_edit'],
  'Reports':[999,'view_edit'],
  'Χάστης Στρωμάτωσης':[999,'view_edit'],
  'Φωτοσημεία':[1,2,3,4,5,6,7,8,999,'view_only','view_edit'],
  'form1':[1,2,3,4,5,6,7,8,999,'view_edit'],
  'Δειγματοληψίες':[1,2,3,4,5,6,7,8,999,'view_edit']
}

 async function testAuth(next,name) {
  if (store.getters.isAuthenticated) {
    next();
  } else {
    var token = localStorage.getItem("token");
    if(token){ 
      await getUser()
    }
    //console.log(token)
    axios
      .post("verifytoken/", { token: token })
      .then(()=>{
        //console.log(response)
        console.log(store.state.permissions)
        console.log(group_view[name])
        if(group_view[name].includes(store.state.group[0]) && group_view[name].includes(store.state.permissions)){
          next()
        }
        //this.$router.push('/dashboard')
        //console.log(response)
      })
      .catch(err => {
        console.log('login')
        //next()
        next("/login").catch(() => {console.log('login')});
        //this.$router.push('/login')
        //next( )
        //store.dispatch('logout')
        store.dispatch("logout");
        console.log(err);
      });
  }
}

async function getUser(){
  var token=localStorage.getItem('token');
  console.log(store.state.user)
  //token=JSON.parse(token)
  var group
  await axios.get('user',{ headers: { Authorization: 'Bearer ' + token }}).then(function(response){
    
   

      group=response.data.groups.filter((item) => {
          if (item !==998) {
              return item;
          }
        })
    

    if(response.data.groups.includes(998)){
      store.commit('set', ['permissions', 'view_only'])

    }else{
      store.commit('set', ['permissions', 'view_edit'])
    }
    store.commit('set', ['group', group])
    store.commit('set', ['user', response.data])
    
  })
}



