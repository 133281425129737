/*import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

const state = {
  sidebarShow: 'responsive',
  sidebarMinimize: false
}

const mutations = {
  toggleSidebarDesktop (state) {
    const sidebarOpened = [true, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarOpened ? false : 'responsive'
  },
  toggleSidebarMobile (state) {
    const sidebarClosed = [false, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarClosed ? true : 'responsive'
  },
  set (state, [variable, value]) {
    state[variable] = value
  }
},


export default new Vuex.Store({
  state,
  mutations
})*/
import Vue from "vue";
import Vuex from "vuex";
import axios from "../axios-auth";
//import globalAxios from 'axios'

import routes from "../router/index.js";
//import axios from '../axios-auth'
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    idToken: null,
    user: null,
    name: null,
    sidebarShow: 'responsive',
    sidebarMinimize: true,
    disabled_tautotita:false,
    selected_dasarxeio:null,
    code_de:null,
    code_de_object:null,
    code_de_all_info:null,
    code:null,
    luttreetype:null,
    luttreestatus:null,
    apokentr:null,
    per_enot:null,
    dasarxeio:null,
    helpModal:[false,false],
    group:null,
    points_anadoxos:null,
    table_checked:null,
    show_fixed_table_check:false,
    alertLogin:false,
    permissions:null

  },
  mutations: {
    toggleSidebarDesktop (state) {
      const sidebarOpened = [true, 'responsive'].includes(state.sidebarShow)
      state.sidebarShow = sidebarOpened ? false : 'responsive'
    },
    toggleSidebarMobile (state) {
      const sidebarClosed = [false, 'responsive'].includes(state.sidebarShow)
      state.sidebarShow = sidebarClosed ? true : 'responsive'
    },
    set (state, [variable, value]) {
      state[variable] = value
    },
    storeUser(state, user) {
      state.user = user;
    },
    pagename(state, name) {
      state.name = name;
    },
    group(state, group) {
      state.group = group;
    },
    user(state, user) {
      state.user = user;
    }
    
  },
  actions: {
    login({ commit,dispatch }, authData) {
      console.log(authData.username)
       axios
        .post("api-token/", {
          username: authData.username,
          password: authData.password
        })
        .then(res => {
          localStorage.setItem("token", res.data.access);
         commit("set", ['alertLogin', false])

          //this.getUser()
          dispatch("getUser",{
            type:'login'
          });
         
        })
        .catch(() => {
          commit("set", ['alertLogin', true])
          
        });
      //commit('storeUser', authData.username)
      //this.$store.state.user=authData.username
    },
    getUser({commit},data){
      var token=localStorage.getItem('token');
      console.log(data.type)
      //token=JSON.parse(token)
      axios.get('user',{ headers: { Authorization: 'Bearer ' + token }}).then(function(response){
        console.log(response)
        localStorage.removeItem("user");
        var group

       
    
          group=response.data.groups.filter((item) => {
              if (item !==998) {
                  return item;
              }
            })

    
        if(response.data.groups.includes(998)){
          commit('set', ['permissions', 'view_only'])
    
        }else{
          commit('set', ['permissions', 'view_edit'])
        }
        localStorage.setItem("user", JSON.stringify(response.data));
        commit("group", group);
        if(data.type==='login'){
          if (token !== null) {
            routes.replace("/dashboard");
          }
        }
      })
    },
    logout({commit}) {
      localStorage.removeItem("token");
      localStorage.removeItem("user"); 
      commit("user", null);
      //localStorage.removeItem('page_name')
      routes.replace("/login");
      //location.reload();
    },
    pageName({ commit }, name) {
      //console.log(name)
      commit("pagename", name.name);
      //this.$store.state.user=authData.username
    }
    /*
    login ({commit, dispatch}, authData) {
      axios.post('http://127.0.0.1:8000/api-token/', {
        username: authData.username,
        password: authData.password,
       // returnSecureToken: true
      })
        .then(res => {
          //console.log(res)
          //const now = new Date()
          //const expirationDate = new Date(now.getTime() + res.data.expiresIn * 1000)
          localStorage.setItem('token', res.data.access)
          //localStorage.setItem('retoken', res.data.refresh)
          //context.commit('updateStorage', { access: res.data.access, refresh: response.data.refresh })


          //localStorage.setItem('userId', res.data.localId)
          //localStorage.setItem('expirationDate', expirationDate)
          
          //dispatch('setLogoutTimer', res.data.expiresIn)
          if(res.data.access !== null){ routes.push({ path: '/dashboard' })}
        })
        .catch(error => console.log(error))
    },*/

    //  logout ({commit}) {
    // localStorage.removeItem('expirationDate')
    //localStorage.removeItem('token')
    //localStorage.removeItem('retoken')
    //localStorage.removeItem('username')
    //localStorage.removeItem('page_name')
    //commit('storeUser', null)
    //  localStorage.removeItem('userId')
    //  routes.replace('/')
    // },
    /*fetchUser ({commit}) {

      const token = localStorage.getItem('token')
      const username = localStorage.getItem('username')

      axios.get('http://127.0.0.1:8000/user/'+ username, { headers: { Authorization: `Bearer ${token}` } })
        .then(response => {
          //console.log(response)
          commit('storeUser', response.data)
          // this.$store.state.APIData = response.data
        })
        .catch(err => {
          console.log(err)
        })
    }*/
  },
  getters: {
    user(state) {
      return state.user;
    },
    PageName(state) {
      return state.name;
    },
    isAuthenticated(state) {
      return state.user !== null;
    }
  }
});
