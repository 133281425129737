import 'core-js/stable'
import Vue from 'vue'
import App from './App'
import router from './router'
import CoreuiVue from '@coreui/vue'
import { iconsSet as icons } from './assets/icons/icons.js'
import store from './store/index.js'
import { BootstrapVue, IconsPlugin,BVToastPlugin } from 'bootstrap-vue'

// Import Bootstrap an BootstrapVue CSS files (order is important)
import '@coreui/coreui/dist/css/coreui.min.css'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import {
  ValidationObserver,
  ValidationProvider,
  extend,
  localize
} from "vee-validate";
import * as rules from "vee-validate/dist/rules";
import en from "vee-validate/dist/locale/en.json";
localize("en", en);
Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule]);
});
// Install VeeValidate components globally
Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);

import { LMap, LTileLayer, LMarker,LCircle ,LCircleMarker,LControl,LControlZoom,LGeoJson,LTooltip,LPopup,LLayerGroup,LWMSTileLayer,LIcon,LControlLayers} from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';

Vue.component('l-map', LMap);
Vue.component('l-tile-layer', LTileLayer);
Vue.component('l-marker', LMarker);
Vue.component('l-circle', LCircle);
Vue.component('l-circle-marker', LCircleMarker);
Vue.component('l-control', LControl);
Vue.component('l-control-zoom', LControlZoom);
Vue.component('l-geo-json', LGeoJson);
Vue.component('l-tooltip', LTooltip);
Vue.component('l-popup', LPopup);
Vue.component('l-layer-group', LLayerGroup);
Vue.component('l-wms-tile-layer', LWMSTileLayer);
Vue.component('l-icon', LIcon);
Vue.component('l-control-layers', LControlLayers);


delete L.Icon.Default.prototype._getIconUrl;
import L from 'leaflet';
L.Icon.Default.mergeOptions({
   iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
   iconUrl: require('leaflet/dist/images/marker-icon.png'),
   shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});


// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
Vue.use(BVToastPlugin)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)


import Multiselect from 'vue-multiselect'

  // register globally
Vue.component('multi-select', Multiselect)

import VueHighcharts from 'vue2-highcharts'

Vue.component('vue-highcharts',VueHighcharts);


Vue.config.performance = true
Vue.use(CoreuiVue)
Vue.prototype.$log = console.log.bind(console)



import VueSpeech from 'vue-speech'
 
 
Vue.use(VueSpeech)



import VuePapaParse from 'vue-papa-parse'
Vue.use(VuePapaParse)

//import JsonCSV from 'vue-json-csv'


import VueJsonToCsv from 'vue-json-to-csv'
//Vue.use(VueJsonToCsv);
Vue.component('VueJsonToCsv', VueJsonToCsv)


import { DraggablePlugin, DraggableDirective } from '@braks/revue-draggable';

// Use as Plugin
Vue.use(DraggablePlugin)

// or
Vue.directive('draggable', DraggableDirective)

import vSelect from 'vue-select'

Vue.component('v-select', vSelect)
import 'vue-select/dist/vue-select.css';
new Vue({
  el: '#app',
  router,
  store,
  icons,
  template: '<App/>',
  components: {
    App
  }
})
